import { useState, useEffect, useRef } from 'react'


// Mui
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery'

import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import SendIcon from '@mui/icons-material/Send';

import FmdGoodIcon from '@mui/icons-material/FmdGood';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

// redux
import { AppDispatch } from '../../store';
import { useDispatch } from 'react-redux';
import { PostContact } from '../../slices/fetchReducer';

// self
import { HandleLoadingImages } from '../../Functions/HandleLoadingImages'
import { MainLoading } from '../../components/index'
import { colors } from '../../colors/colors'
import { Iinfo } from '../../types/interface'


declare global {
    interface Window {
        grecaptcha: any;
    }
}
interface Iprops {
    info: Iinfo
    componentInfo : {[key: string]:string}
    type: string

}
const Contact = (props:Iprops) => {
    const { info, componentInfo, type } = props
    const siteKey = process.env?.REACT_APP_SITE_KEY || "" as string

    const image_ref = useRef(0)
    const matches = useMediaQuery('(max-width:690px)');

	const dispatch: AppDispatch = useDispatch()

    const formdatainit = {
        name:"daniel heineras",
        email:"daniel.heineras@gmail.com",
        phone:"0707946979",
        message:"Detta är ett meddelande..."        
    }
    const [formData, setFormData] = useState(formdatainit)
    const [loading, setLoading] = useState(true)
    const [submitLoading, setSubmitLoading] = useState(false)
	const [message, setMessage] = useState("")
    const [token, setToken] = useState("")

    useEffect(() => {
        // Add reCaptcha
        try {
            
        
            const script = document.createElement("script")
            script.src = `https://www.google.com/recaptcha/api.js?render=${siteKey}`
            script.addEventListener("load", handleLoaded)
            document.body.appendChild(script)
        } catch (error) {
            console.log(error)   
        }
      }, [])

    const handleLoaded = async(_:any) => {
        try {
            await window.grecaptcha.ready(async(_:any) => {
                await window.grecaptcha
                  .execute(`${siteKey}`, { action: "contact" })
                  .then((res:string) => {              
                    setToken(res)
                  }).catch((error:any) => {
                    console.log(error)        
                  })
              })    
        } catch (error) {
            console.log(error)
        }
        
        
    }


    //* Address Form Component
    const AddressForm = (setFormData:(prev:any)=>void ) => {   

        const handleFormData = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { name, value } = event.target
            setFormData((prev:any) => ({...prev, [name]:value}))
        }

        return (
            <Box>         
              <Grid container spacing={3}>
                <Grid item xs={12} >
                  <TextField
                    required                    
                    name="name"                    
                    label={componentInfo?.Name_TextField}
                    sx={{label: {color:"grey.700"}}}       
                    value={formData?.name}
                    onChange={handleFormData}
                    fullWidth                    
                    variant="standard"
                  />
                </Grid>
                                           
                <Grid item xs={12}>
                  <TextField                                                            
                    name="phone"
                    fullWidth                    
                    variant="standard"
                    sx={{label: {color:"grey.700"}}}
                    label={componentInfo?.Phone_TextField}                        
                    value={formData?.phone}
                    onChange={handleFormData} 
                  />
                </Grid> 
                <Grid item xs={12}>
                  <TextField
                    required                    
                    name="email"                    
                    fullWidth                    
                    variant="standard"
                    sx={{label: {color:"grey.700"}}}
                    label={componentInfo?.Email_TextField}                        
                    value={formData?.email}
                    onChange={handleFormData} 
                  />
                </Grid>
				<Grid item xs={12}>
                  <TextField
                    required                    
                    name="message"                    
                    fullWidth
					multiline
					maxRows={5}
                    variant="standard"
                    sx={{label: {color:"grey.700"}}}
                    label={componentInfo?.Message_TextField}                        
                    value={formData?.message}
                    onChange={handleFormData} 
                  />
                </Grid>
              </Grid>
            </Box>
        );
    }

    
    const Submit = async(event:React.FormEvent) => {
        event.preventDefault();
		setSubmitLoading(true)
		setMessage("")
		let temp = structuredClone(formData)
		Object.assign(temp,{recaptchaToken: token},{type: type})
		await dispatch(PostContact(temp))
			.unwrap()
			.then(res => {
				const messageres = res?.body?.message
				if(res?.statusCode === 200){
					setMessage(messageres || "Tack för ditt mail!")
				}else{
					setMessage(messageres || `Ditt mail skickades inte, var snäll och försök igen eller skriv direkt till vår mail \n\n ${info?.CompanyInfo?.Email_Value}`)
				}
			})
            
        setSubmitLoading(false)        
            
		
    }
    
    return (
        <Box component="section" sx={{minHeight:"100vh",justifyContent:"flex-start",pt:"10rem"}} className="flex__center-c " bgcolor={colors?.bg?.default} id="service">
   
            

            {/* Paper */}
            <Paper elevation={10} sx={{backgroundColor: colors?.bg?.light, width:"95vw", minHeight: matches ? "80vh": "80vh", justifyContent:"space-between", maxWidth:"xl"}} className="flex__center-r flex__r-c" >

            

                {/* Form */}
				{!message ? 
                <Box component="form" onSubmit={Submit} sx={{position:"relative",height:"100%",width: matches ? "95%":"50%", m:"0 0.5rem", justifyContent:"space-around",alignSelf:"center"}} className="flex__center-c">

                    <Typography variant='h2' color="primary" sx={{textAlign:"center"}}>
                        {componentInfo?.Title_Form}
                    </Typography>

                    {AddressForm(setFormData)}              

                    <Button
                        color='primary'
                        variant="contained"
                        type='submit'
                        disabled={submitLoading}
                        endIcon={<SendIcon />}                                
                        sx={{ mt: 3, ml: 1 }}
                    >
                        {componentInfo?.Button_Send}
                        {submitLoading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "teal",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                            )}
                    </Button>


                </Box>
				:
				<Box className="flex__center-c" width={"50%"} height={"20rem"}>
					<Typography variant='h5' sx={{textAlign:"center"}}>{message}</Typography>
                    <Button variant='outlined' sx={{mt:"0.5rem"}} onClick={()=>window.location.reload()}>{componentInfo?.Button_Back}</Button>
				</Box>
				}

                {/* img */}
                <Box sx={{position:"relative",minHeight:"80vh",width: matches ? "100%":"50%",zIndex:1,justifyContent:"space-evenly",mt: matches ? "1rem": 0}} className="flex__center-c">
                    <img className='header-img-noblur' style={{height: "100%",width:"100%",filter: "brightness(0.4)"}} src={componentInfo?.Image} alt="header" onLoad={()=>HandleLoadingImages(1, image_ref, setLoading)} />   
                    <Box>
                        <Typography className="flex__center-r" color="primary.main" variant='h3' sx={{textAlign:"center"}}> <FmdGoodIcon sx={{mr:"0.5rem"}}/>{componentInfo?.Address}</Typography>
                        <Typography color="primary.contrastText" variant='h4' sx={{textAlign:"center"}}> {info?.CompanyInfo?.Address_Value}</Typography>
                    </Box>
                    <Box>
                        <Typography className="flex__center-r" color="primary.main" variant='h3' sx={{textAlign:"center"}}> <AttachEmailIcon sx={{mr:"0.5rem"}}/>{componentInfo?.Email}</Typography>                        
                        <Typography color="primary.contrastText" variant='h4' sx={{textAlign:"center"}}> {info?.CompanyInfo?.Email_Value}</Typography>
                    </Box>
                    <Box>
                        <Typography className="flex__center-r" color="primary.main" variant='h3' sx={{textAlign:"center"}}> <LocalPhoneIcon sx={{mr:"0.5rem"}}/>{componentInfo?.Phone}</Typography>
                        <Typography color="primary.contrastText" variant='h4' sx={{textAlign:"center"}}> {info?.CompanyInfo?.Phone_Value}</Typography>
                    </Box>
                    {/* 
                    


                    
                    */}
                                                            
                </Box>

            </Paper>


            {/* LOADING SCREEN */}
            {loading && 
                <MainLoading loading={loading} info={info}  />
            }
      
        </Box>   
    )
}

export default Contact